import React from 'react';
import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <div className="App">
      <div>
        <div className="App-title">
          <b>rick.chou</b><br/>
          <div className="App-hr"/>
          <b><span className="App-sub-text">software</span>.dev<span className="App-sub-text">eloper</span></b>
        </div>
        <div className="App-links">
          <a href="https://www.linkedin.com/in/iamrick/"><div className="App-link-icon">&#10070;</div> linkedin</a><br/>
          <a href="https://github.com/rkdk"><span className="App-link-icon">&#10066;</span> github</a>
        </div>
      </div>
    </div>
  );
}

export default App;
